import React from 'react';

const FooterTab = ({userHash}) => {
    return (
        <div className="p-1 mt-5">
            <div className="fixed-bottom bg-light tab-bottom">
                <div className="d-flex justify-content-around align-items-center">
                    <a className="d-flex flex-column align-items-center tab-link active" href="/">
                        <svg width="16" height="16" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.325 13.3737L18.3241 4.28167C17.9568 3.94531 17.4767 3.75876 16.9787 3.75879C16.4806 3.75882 16.0006 3.94544 15.6333 4.28185L5.63415 13.3736C5.42815 13.5612 5.26353 13.7897 5.1508 14.0445C5.03808 14.2993 4.97974 14.5748 4.97949 14.8534V26.411C4.97949 26.6737 5.03123 26.9338 5.13175 27.1764C5.23228 27.4191 5.37962 27.6396 5.56536 27.8253C5.75111 28.011 5.97162 28.1583 6.2143 28.2588C6.45698 28.3593 6.71708 28.411 6.97974 28.411L12.9789 28.4101C13.2441 28.4101 13.4984 28.3047 13.6859 28.1172C13.8734 27.9296 13.9788 27.6753 13.9788 27.4101V19.4101H19.9788V27.4101C19.9788 27.6753 20.0841 27.9296 20.2716 28.1172C20.4591 28.3047 20.7134 28.4101 20.9786 28.4101L26.9792 28.411H26.9795C27.2421 28.411 27.5022 28.3593 27.7449 28.2588C27.9875 28.1583 28.208 28.0109 28.3937 27.8252C28.5794 27.6395 28.7267 27.419 28.8273 27.1764C28.9278 26.9337 28.9795 26.6736 28.9795 26.411V14.8535C28.9792 14.5749 28.9209 14.2994 28.8082 14.0446C28.6955 13.7898 28.5309 13.5613 28.325 13.3737Z" fill="#C53036"/>
                        </svg>
                        Главная страница
                    </a>
                    <a className="d-flex flex-column align-items-center tab-link" href={"https://team.itsp.kz/login?hash="+userHash}>
                        <svg width="16" height="16" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13.0768" cy="12.7526" r="12.6586" fill="#BDBDBD"/>
                            <path d="M11.9722 13.1691C9.64136 12.3105 8.96152 10.2006 9.29537 8.63658C9.68992 6.77203 11.2681 5.49016 13.1498 5.51469C14.9708 5.53309 16.543 6.8763 16.8768 8.72244C17.1864 10.4337 16.3366 12.4025 14.1757 13.1691C14.3274 13.2059 14.4245 13.2305 14.5217 13.2489C17.3988 13.8929 19.5476 16.5609 19.578 19.511C19.578 19.7564 19.5537 19.9894 19.2502 19.9894C18.9528 19.9894 18.9103 19.7625 18.9042 19.5172C18.8496 16.5977 16.628 14.1075 13.7022 13.8009C10.564 13.4697 7.86284 15.6532 7.33475 18.6278C7.28619 18.91 7.26798 19.1982 7.25584 19.4804C7.2437 19.7318 7.23156 19.971 6.90985 19.9894C6.67312 20.0078 6.56993 19.83 6.576 19.4497C6.62456 16.518 8.76727 13.8867 11.6202 13.2427C11.7234 13.2243 11.8205 13.1998 11.9722 13.1691ZM16.2698 9.41551C16.2759 7.6491 14.8434 6.19549 13.0891 6.18936C11.3228 6.18323 9.87809 7.64297 9.89023 9.42165C9.90237 11.2003 11.3288 12.6355 13.0831 12.6294C14.8373 12.6294 16.2638 11.1881 16.2698 9.41551Z" fill="white" stroke="white"/>
                        </svg>
                        Личный кабинет
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FooterTab;