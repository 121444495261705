import React, {useState} from 'react';
import { QrReader } from 'react-qr-reader';

const Qr = (props) => {

    const ViewFinder = () => {
      return (
          <svg width="50px" viewBox="0 0 100 100" style={{top: '0px', left: '0px', zIndex: 1, boxSizing: 'border-box', border: '100px solid rgb(47 52 63 / 37%)', position: 'absolute', width: '100%'}}>
              <path fill="none" d="M13,0 L0,0 L0,13" stroke="rgba(255, 0, 0, 0.5)" strokeWidth="5"></path>
              <path fill="none" d="M0,87 L0,100 L13,100" stroke="rgba(255, 0, 0, 0.5)" strokeWidth="5"></path>
              <path fill="none" d="M87,100 L100,100 L100,87" stroke="rgba(255, 0, 0, 0.5)" strokeWidth="5"></path>
              <path fill="none" d="M100,13 L100,0 87,0" stroke="rgba(255, 0, 0, 0.5)" strokeWidth="5"></path>
          </svg>
      )
    }

    return (
        <div className="qr-container">
            <div className="d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-qr-code" viewBox="0 0 16 16">
                    <path d="M2 2h2v2H2V2Z"></path>
                    <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"></path>
                    <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"></path>
                    <path
                        d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"></path>
                    <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"></path>
                </svg>
                <span className="ml-2">ITSP QR</span>
            </div>
            <QrReader
                constraints={{
                    facingMode: 'environment'
                }}
                onResult={(result, error) => {
                    props.callbackHandler(result)
                }}
                ViewFinder={ViewFinder}
                style={{ width: '100%' }}
            />
            <div className="d-flex justify-content-center align-items-center">
                <button className="input-group-text qr-close-button" onClick={()=>props.callbackCloseHandler()}>
                    <svg fill="#fff" width="40px" height="40px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-m</title><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm75.31,260.69a16,16,0,1,1-22.62,22.62L256,278.63l-52.69,52.68a16,16,0,0,1-22.62-22.62L233.37,256l-52.68-52.69a16,16,0,0,1,22.62-22.62L256,233.37l52.69-52.68a16,16,0,0,1,22.62,22.62L278.63,256Z"/></svg>
                </button>
            </div>
        </div>
    );
};

export default Qr;